import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function UserAccess() {

  this.checkValid = checkValid

  this.data = {
    ATM: false,
    maxWithdrawAll: '',
    maxWithdrawWeek: '',
    maxWithdrawDay: '',
    maxPercentWithdraw: '',
    maxWithoutTracking: '',
    maxWithoutTrackingDayLimit: '',

    statusNewUser: false,

    paypalPay: false,

    fulfilmentAmazon: false,
    fulfilmentMerchant: false,

    trackingNumEtsy: false,
    trackingNumAmazon: false,

    etsyAPIToEmail: false,

    // easyCabinet: false,
    useKITGroup: false,
    showPayPalButton: false,
    abilityCreatePayPal: false,

    consolidationPrepayment: false,
    googleAuthentication: false,
    paymentListings: false,

    withdrawFundsWithoutTracking: false,
    blockUser: false,
    allowAccessWithNegativeBalance: false,
    orderExpertUserInterface: false,
    ICreateLabelInMinus: false,

    userUseScales: false,

    changeAddressLabelsYourself: false,

    useEasyCabinet: false,

  }

  this.validation = {
    maxWithdrawAll: false,
    maxWithdrawWeek: false,
    maxWithdrawDay: false,
    maxPercentWithdraw: false,
  }

  this.validationTranslate = {
    maxWithdrawAll: '',
    maxWithdrawWeek: '',
    maxWithdrawDay: '',
    maxPercentWithdraw: '',
  }

  this.validationTxt = {
    maxWithdrawAll: false,
    maxWithdrawWeek: false,
    maxWithdrawDay: false,
    maxPercentWithdraw: false,
  }


  /**
   * Getters
   */
  this.getATM = () => {
    return this.data.ATM
  }
  this.getMaxWithdrawAll = () => {
    return this.data.maxWithdrawAll
  }
  this.getMaxWithdrawWeek = () => {
    return this.data.maxWithdrawWeek
  }
  this.getMaxWithdrawDay = () => {
    return this.data.maxWithdrawDay
  }
  this.getMaxPercentWithdraw = () => {
    return this.data.maxPercentWithdraw
  }
  this.getStatusNewUser = () => {
    return this.data.statusNewUser
  }
  this.getPapalPay = () => {
    return this.data.paypalPay
  }
  this.getWithdrawFundsWithoutTracking = () => {
    return this.data.withdrawFundsWithoutTracking
  }
  this.getMaxWithoutTrackingDayLimit = () => {
    return this.data.maxWithoutTrackingDayLimit
  }
  this.getUseKITGroup = () => {
    return this.data.useKITGroup
  }
  this.getFulfilmentAmazon = () => {
    return this.data.fulfilmentAmazon
  }
  this.getFulfilmentMerchant = () => {
    return this.data.fulfilmentMerchant
  }
  this.getBlockUser = () => {
    return this.data.blockUser
  }
  this.getShowPayPalButton = () => {
    return this.data.showPayPalButton
  }
  this.getPaymentListings = () => {
    return this.data.paymentListings
  }
  this.getGoogleAuthentication = () => {
    return this.data.googleAuthentication
  }
  this.getConsolidationPrepayment = () => {
    return this.data.consolidationPrepayment
  }
  this.getTrackingNumEtsy = () => {
    return this.data.trackingNumEtsy
  }
  this.getTrackingNumAmazon = () => {
    return this.data.trackingNumAmazon
  }
  this.getEtsyAPIToEmail = () => {
    return this.data.etsyAPIToEmail
  }
  this.getAllowAccessWithNegativeBalance = () => {
    return this.data.allowAccessWithNegativeBalance
  }
  this.getOrderExpertUserInterface = () => {
    return this.data.orderExpertUserInterface
  }
  this.getUserUseScales = () => {
    return this.data.userUseScales
  }
  this.getICreateLabelInMinus = () => {
    return this.data.ICreateLabelInMinus
  }
  this.getChangeAddressLabelsYourself = () => {
    return this.data.changeAddressLabelsYourself
  }
  this.getUseEasyCabinet = () => {
    return this.data.useEasyCabinet
  }


  /**
   * Setters
   */
  this.setATM = (val) => {
    this.data.ATM = val
  }
  this.setMaxWithdrawAll = (val) => {
    this.data.maxWithdrawAll = val ? val : ''
  }

  this.setMaxWithdrawWeek = (val) => {
    this.data.maxWithdrawWeek = val ? val : ''
  }
  this.setMaxWithdrawDay = (val) => {
    this.data.maxWithdrawDay = val ? val : ''
  }
  this.setMaxPercentWithdraw = (val) => {
    this.data.maxPercentWithdraw = val ? val : ''
  }
  this.setStatusNewUser = (val) => {
    this.data.statusNewUser = val
  }
  this.setPaypalPay = (val) => {
    this.data.paypalPay = val
  }
  this.setWithdrawFundsWithoutTracking = (val) => {
    this.data.withdrawFundsWithoutTracking = val ? val : ''
  }
  this.setMaxWithoutTrackingDayLimit = (val) => {
    this.data.maxWithoutTrackingDayLimit = val
  }

  this.setUseKITGroup = (val) => {
    this.data.useKITGroup = val ? val : 0
  }
  this.setFulfilmentAmazon = (val) => {
    this.data.fulfilmentAmazon = val ? val : 0
  }
  this.setFulfilmentMerchant = (val) => {
    this.data.fulfilmentMerchant = val ? val : 0
  }
  this.setShowPayPalButton = (val) => {
    this.data.showPayPalButton = val
  }
  this.setPaymentListings = (val) => {
    this.data.paymentListings = val
  }
  this.setGoogleAuthentication = (val) => {
    this.data.googleAuthentication = val
  }
  this.setBlockUser = (val) => {
    this.data.blockUser = val
  }
  this.setConsolidationPrepayment = (val) => {
    this.data.consolidationPrepayment = val
  }
  this.setTrackingNumEtsy = (val) => {
    this.data.trackingNumEtsy = val
  }
  this.setTrackingNumAmazon = (val) => {
    this.data.trackingNumAmazon = val
  }
  this.setEtsyAPIToEmail = (val) => {
    this.data.etsyAPIToEmail = val
  }
  this.setAllowAccessWithNegativeBalance = (val) => {
    this.data.allowAccessWithNegativeBalance = val
  }
  this.setOrderExpertUserInterface = (val) => {
    this.data.orderExpertUserInterface = val
  }
  this.setUserUseScales = (val) => {
    this.data.userUseScales = val
  }
  this.setICreateLabelInMinus = (val) => {
    this.data.ICreateLabelInMinus = val
  }
  this.setChangeAddressLabelsYourself = (val) => {
    this.data.changeAddressLabelsYourself = val
  }
  this.setUseEasyCabinet = (val) => {
    this.data.useEasyCabinet = val
  }
}


/*****************   SETTERS   ******************/

UserAccess.prototype.setUserAccessItem = function (userSettings, user) {


  this.setATM(userSettings?.atm_output === '1')
  this.setMaxWithdrawAll(userSettings?.atm_limit_for_one_time ? parseFloat(userSettings?.atm_limit_for_one_time).toFixed(2) : 0)
  this.setMaxWithdrawWeek(userSettings?.atm_limit_for_week ? parseFloat(userSettings?.atm_limit_for_week).toFixed(2) : 0)
  this.setMaxWithdrawDay(userSettings?.atm_limit_for_per_day ? parseFloat(userSettings?.atm_limit_for_per_day).toFixed(2) : 0)
  this.setMaxPercentWithdraw(userSettings?.atm_limit_max_percent ? parseFloat(userSettings?.atm_limit_max_percent).toFixed(2) : 0)

  this.setStatusNewUser(userSettings?.n_mark === 1)

  this.setPaypalPay(userSettings?.use_paypal === 1)
  this.setShowPayPalButton(userSettings?.use_paypal_button === 1)
  this.setPaymentListings(userSettings?.use_payment_listing === 1)

  this.setTrackingNumEtsy(userSettings?.send_track_number_etsy === '1')
  this.setTrackingNumAmazon(userSettings?.send_track_number_amazon === '1')
  this.setEtsyAPIToEmail(userSettings?.receive_etsy_information_email === '1')

  this.setFulfilmentAmazon(user.fulfilment_by_amazon)
  this.setFulfilmentMerchant(user.fulfilment_by_merchant)
  this.setUseKITGroup(userSettings.use_kit_group_bank)
  this.setWithdrawFundsWithoutTracking(userSettings?.withdraw_without_tracking === '1')
  this.setMaxWithoutTrackingDayLimit(userSettings.withdraw_without_tracking_day_limit)

  this.setConsolidationPrepayment(userSettings.consolidation_order_prepayment === 1)

  this.setGoogleAuthentication(userSettings.allow_access_without_google_auth === 1)

  this.setAllowAccessWithNegativeBalance(userSettings.allow_access_with_negative_balance === 1)
  this.setOrderExpertUserInterface(userSettings?.express_order_template_type === 1)

  this.setUserUseScales(userSettings?.user_use_scales)

  this.setUseEasyCabinet(user?.easy_cabinet)

  this.setICreateLabelInMinus(userSettings?.self_create_label_no_money)

  this.setBlockUser(user?.locked)

  this.setChangeAddressLabelsYourself(userSettings?.self_label_address)


  // this.callingCourier.statusNewUser= val.statusNewUser;
  // this.callingCourier.fulfilmentAmazon= val.fulfilmentAmazon;
  // this.callingCourier.fulfilmentMerchant= val.fulfilmentMerchant;
  // this.callingCourier.trackingNumEtsy= val.trackingNumEtsy;
  // this.callingCourier.trackingNumAmazon= val.trackingNumAmazon;
  // this.callingCourier.etsyAPIToEmail= val.etsyAPIToEmail;
  // this.callingCourier.easyCabinet= val.easyCabinet;
  // this.callingCourier.abilityCreatePayPal= val.abilityCreatePayPal;
  // this.callingCourier.easyRegistration= val.easyRegistration;
  // this.callingCourier.googleAuthentication= val.googleAuthentication;
  // this.callingCourier.withdrawFundsWithoutTracking= val.withdrawFundsWithoutTracking;
  // this.callingCourier.blockUser= val.blockUser;
}



/*****************   VALIDATION   ******************/

UserAccess.prototype.userAccessValidate = function () {

  let validationItems = {
    maxWithdrawAll: this.getMaxWithdrawAll(),
    maxWithdrawWeek: this.getMaxWithdrawWeek(),
    maxWithdrawDay: this.getMaxWithdrawDay(),
    maxPercentWithdraw: this.getMaxPercentWithdraw(),
  }

  let validationOptions = {
    maxWithdrawAll: {type: ['not-zero', 'empty']},
    maxWithdrawWeek: {type: ['not-zero', 'empty']},
    maxWithdrawDay: {type: ['not-zero', 'empty']},
    maxPercentWithdraw: {type: ['not-zero', 'empty']},
  }

  console.log(validationItems);
  console.log(validationOptions);


  return this.checkValid(validationItems, validationOptions)
}



/*****************   GETTERS   ******************/

UserAccess.prototype.getUserAccessItem = function () {

  return {
    "atm_output": this.getATM() ? '1' : '0',
    "atm_limit_for_one_time": this.getMaxWithdrawAll(),
    "atm_limit_for_week": this.getMaxWithdrawWeek(),
    "atm_limit_for_per_day": this.getMaxWithdrawDay(),
    "atm_limit_max_percent": this.getMaxPercentWithdraw(),
    "withdraw_without_tracking": this.getWithdrawFundsWithoutTracking() ? '1' : '0',
    // "withdraw_without_tracking_day_limit": parseInt(this.getMaxWithoutTrackingDayLimit()) > 0 ? parseInt(this.getMaxWithoutTrackingDayLimit()) : 0,
    "withdraw_without_tracking_day_limit": parseInt(this.getMaxWithoutTrackingDayLimit()) ? parseInt(this.getMaxWithoutTrackingDayLimit()) : 0,


    "use_kit_group_bank": this.getUseKITGroup(),

  }

  // return {
  //   'maxWithdrawAll': this.callingCourier.maxWithdrawAll,
  //   'maxWithdrawWeek': this.callingCourier.maxWithdrawWeek,
  //   'maxWithdrawDay': this.callingCourier.maxWithdrawDay,
  //   'maxPercentWithdraw': this.callingCourier.maxPercentWithdraw,
  //   'statusNewUser': this.callingCourier.statusNewUser,
  //   'fulfilmentAmazon': this.callingCourier.fulfilmentAmazon,
  //   'fulfilmentMerchant': this.callingCourier.fulfilmentMerchant,
  //   'trackingNumEtsy': this.callingCourier.trackingNumEtsy,
  //   'trackingNumAmazon': this.callingCourier.trackingNumAmazon,
  //   'etsyAPIToEmail': this.callingCourier.etsyAPIToEmail,
  //   'easyCabinet': this.callingCourier.easyCabinet,
  //   'abilityCreatePayPal': this.callingCourier.abilityCreatePayPal,
  //   'easyRegistration': this.callingCourier.easyRegistration,
  //   'googleAuthentication': this.callingCourier.googleAuthentication,
  //   'withdrawFundsWithoutTracking': this.callingCourier.withdrawFundsWithoutTracking,
  //   'blockUser': this.callingCourier.blockUser,
  // }
}

UserAccess.prototype.getUserAccessFullfilment = function () {
  return{
    "fulfilment_by_merchant": this.getFulfilmentMerchant() ? 1 : 0,
    "fulfilment_by_amazon": this.getFulfilmentAmazon() ? 1 : 0,
  }
}

UserAccess.prototype.getUserPayment = function () {
  return {
    "use_paypal": this.getPapalPay(),
    "use_payment_listing": this.getPaymentListings(),
    "use_paypal_button": this.getShowPayPalButton(),
    "consolidation_order_prepayment": this.getConsolidationPrepayment(),
    "allow_access_without_google_auth": this.getGoogleAuthentication(),
  }
}

UserAccess.prototype.getUserPaypalPay = function () {
  return{
    "use_paypal": this.getPapalPay(),
  }
}

UserAccess.prototype.getUserUserSettings = function () {
  return{
    "send_track_number_etsy": this.getTrackingNumEtsy() ? '1' : '0',
    "send_track_number_amazon": this.getTrackingNumAmazon() ? '1' : '0',
    "receive_etsy_information_email": this.getEtsyAPIToEmail() ? '1' : '0',
    "allow_access_with_negative_balance": this.getAllowAccessWithNegativeBalance() ? 1 : 0,
    "express_order_template_type": this.getOrderExpertUserInterface() ? 1 : 0,
    "user_use_scales": this.getUserUseScales(),
    "self_create_label_no_money": this.getICreateLabelInMinus(),
  }
}
