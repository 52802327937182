import { render, staticRenderFns } from "./AccessFunctionality.vue?vue&type=template&id=131a18ec&scoped=true"
import script from "./AccessFunctionality.vue?vue&type=script&lang=js"
export * from "./AccessFunctionality.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131a18ec",
  null
  
)

export default component.exports